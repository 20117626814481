import React, { Component } from 'react';
import './Header.css'
import logo from '../assets/images/logo_tattooattack.png'

class Header extends Component {
    render() {
        return (
            <div className="Header">
                <img src={logo} alt="Tattoo Attack" />
                <div className="icones">
                    <div className="icon">
                        <i className="fas fa-phone-square"></i>
                        (11) 3062-1914
                    </div>
                    <div className="icon link" onClick={()=> window.open(`https://www.instagram.com/tattoo_attack/`,"_blank")}>
                        <i className="fab fa-instagram"></i>
                        @tattoo_attack
                    </div>
                    <div className="icon link" onClick={()=> window.open(`https://www.facebook.com/tattooattack/`,"_blank")}>
                        <i className="fab fa-facebook-square"></i>
                        /tattooattack
                    </div>
                    <div className="icon link" onClick={()=> window.open(`https://www.facebook.com/profile.php?id=1417610914`,"_blank")}>
                        <i className="fab fa-facebook-square"></i>
                        /tatttooattackerick
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;