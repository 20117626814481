import React, { Component } from 'react';
import './Mapa.css'
class Mapa extends Component {
    render() {
        return (
            <div className="Mapa">
                <div className="title"><i className="fas fa-map-marked-alt"></i> Localização</div>
                <iframe title="Mapa do estudio" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.379356709969!2d-46.67943018453342!3d-23.55481508468608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce579c9b7e6811%3A0xc216b111cdaf2532!2sTattoo%20Attack!5e0!3m2!1spt-BR!2sbr!4v1580178626302!5m2!1spt-BR!2sbr" width="100%" height="300" frameborder="0" allowfullscreen="false"></iframe>
            </div>
        );
    }
}

export default Mapa;